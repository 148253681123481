import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['confirmLeave', 'cancelLeave', 'modal']

  connect() {
    console.log('hello from exit waiting controller')
    this.url

    document.addEventListener('click', this.preventLeavePage.bind(this))
  }

  preventLeavePage(e) {
    const target = e.target
    const link = target.closest('a')
    if (target.nodeName === 'A' && target.getAttribute('target') !== '_blank') {
      e.preventDefault()
      const modal = document.querySelector('.modal-warning')
      modal.classList.add('active')

      this.url = link.href
    }
  }

  confirmLeave() {
    console.log('Confirmed leave')
    window.location.href = this.url
  }

  cancelLeave() {
    console.log('Cancel leave')
    const modal = document.querySelector('.modal-warning')
    modal.classList.remove('active')
  }
}
