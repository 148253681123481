import { Controller } from "stimulus"
import HelpersController from "./helpers_controller"
import Tagify from '@yaireo/tagify'

export default class extends Controller {
  static targets = ['tags']
  tagify = null

  initialize() {
    console.log('hello from search controller');
    this.helpers = new HelpersController()
    this.setListController()
  }

  async connect() {
    if (this.hasTagsTarget) {
      let input = this.tagsTarget
      const allTags = await this.helpers._getRequest(input.getAttribute("data-all-tags"));
      this.tagify = new Tagify(input, {
        whitelist: allTags,
        enforceWhitelist: true,
        dropdown: {
          enabled: 1,
        }
      });
      this.tagify.on('add', this.onAddTag)
        .on('remove', this.onRemoveTag);
    }

  }
  disconnect() {
  }

  onAddTag = (e) => {
    let tags_params = JSON.parse(this.tagsTarget.value).map(x => x.value).join(',');
    this.helpers._update_state('tags', tags_params)
    this.listLoadController.loadList()
    this.helpers.setParamsCSV()
  }

  onRemoveTag = (e) => {
    let tags_params = this.tagify.value.map(x => x.value).join(',');
    this.helpers._update_state('tags', tags_params)
    this.listLoadController.loadList()
    this.helpers.setParamsCSV()
  }

  inputSearch(e) {
    this.helpers._update_state('search', e.target.value)
    this.listLoadController.loadList()
    this.helpers.setParamsCSV()
  }

  inputSearchCV(e) {
    this.helpers._update_state('cv_search', e.target.value)
    this.listLoadController.loadList()
    this.helpers.setParamsCSV()
  }

  selectStatus(e) {
    this.helpers._update_state('status', e.target.value)
    this.listLoadController.loadList()
    this.helpers.setParamsCSV()
  }

  selectVacancyStatus(e) {
    this.helpers._update_state('vacancy_status', e.target.value)
    this.listLoadController.loadList()
    this.helpers.setParamsCSV()
  }

  selectRank(e) {
    this.helpers._update_state('rank', e.target.value)
    this.listLoadController.loadList()
    this.helpers.setParamsCSV()
  }

  selectGender(e) {
    this.helpers._update_state('gender', e.target.value)
    this.listLoadController.loadList()
    this.helpers.setParamsCSV()
  }

  selectCadet(e) {
    this.helpers._update_state('cadet', e.target.value)
    this.listLoadController.loadList()
    this.helpers.setParamsCSV()
  }

  selectBidSource(e) {
    this.helpers._update_state('bid_source', e.target.value)
    this.listLoadController.loadList()
    this.helpers.setParamsCSV()
  }

  selectEmailValid(e) {
    this.helpers._update_state('email_valid', e.target.value)
    this.listLoadController.loadList()
    this.helpers.setParamsCSV()
  }

  selectCombatExperience(e) {
    this.helpers._update_state('combat_experience', e.target.value)
    this.listLoadController.loadList()
    this.helpers.setParamsCSV()
  }

  selectMilitaryTraining(e) {
    this.helpers._update_state('military_training', e.target.value)
    this.listLoadController.loadList()
    this.helpers.setParamsCSV()
  }

  selectCurrentMilitary(e) {
    this.helpers._update_state('current_military', e.target.value)
    this.listLoadController.loadList()
    this.helpers.setParamsCSV()
  }

  candidateStatus(e) {
    this.helpers._update_state('candidate_status', e.target.value)
    this.listLoadController.loadList()
    this.helpers.setParamsCSV()
  }

  selectPrescreen(e) {
    this.helpers._update_state('prescreen', e.target.value)
    this.listLoadController.loadList()
    this.helpers.setParamsCSV()
  }

  selectCategory(e) {
    this.helpers._update_state('category', e.target.value)
    this.listLoadController.loadList()
    this.helpers.setParamsCSV()
  }

  selectSpecialty(e) {
    this.helpers._update_state('specialty', e.target.value)
    this.listLoadController.loadList()
    this.helpers.setParamsCSV()
  }

  selectResponsible(e) {
    this.helpers._update_state('responsible', e.target.value)
    this.listLoadController.loadList()
    this.helpers.setParamsCSV()
  }

  selectCompany(e) {
    this.helpers._update_state('company', e.target.value)
    this.listLoadController.loadList()
    this.helpers.setParamsCSV()
  }

  selectRecommender(e) {
    this.helpers._update_state('recommender', e.target.value)
    this.listLoadController.loadList()
    this.helpers.setParamsCSV()
  }

  setListController() {
    setTimeout(() => {
      this.listLoadController = this.application.getControllerForElementAndIdentifier(
        document.getElementsByClassName('list')[0],
        "components--list-load"
      );
    }, 0)
  }
}
